<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 18:52:27
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-12 14:57:33
-->
<template>
  <div class="login">
    <div class="w ww denglu">
      <div class="pc_fl denglu_lf">
        <h2>{{this.$t('welcome')}}</h2>
        <p>{{this.$t('loginIntro')}}</p>
        <div class="denglu_lf_1">
          <span>{{this.$t('accountNum')}}</span
          ><input
            v-model="loginFrom.userCode"
            name=""
            type="text"
            :placeholder="this.$t('userName')"
          />
        </div>
        <div class="denglu_lf_1">
          <span>{{this.$t('passWord')}}</span
          ><input
            v-model="loginFrom.userPwd"
            name=""
            type="password"
            :placeholder="this.$t('pPassword')"
          />
        </div>
        <div class="denglu_lf_2">
          <!-- <label class="demo--label">
            <input class="demo--radio" type="checkbox"  name="demo-checkbox2" />
            <span class="demo--checkbox demo--radioInput" ></span>{{this.$t('autoLogin')}}
          </label> -->
          <a @click="changePassword">{{this.$t('forgetPassword')}}</a>
          <div class="clear"></div>
        </div>
        <div class="denglu_lf_3">
          <input name="" type="button" :value="this.$t('login')" @click="login" @keyup.enter="btnLogin"/>
        </div>
      </div>
      <div class="pc_fr denglu_rt">
        <!-- <div class="denglu_rt_1">
          <img src="@/assets/img/56.png" width="100%" />
        </div> -->
        <h3>{{this.$t('loginTip')}}</h3>
        <p>{{this.$t('benefit')}}</p>
        <a  @click="register"
          >{{this.$t('ReNow')}}<img src="@/assets/img/57.png"
        /></a>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>

import { userLogin } from "./model";
import { showLayer } from "@/utils/com";
export default {
  data() {
    return {
      // 记录复选框状态
      state:false,
      // 自动登录复选框
      loginFrom: {
        userCode: "",
        userPwd: "",
      },
    };
  },
  created(){
    this.btnLogin()
  },
  methods: {
    register() {
      this.$router.push("/register");
    },
    login() {
      if (this.loginFrom.userPwd === "" || this.loginFrom.userMobile === "") {
        showLayer("toast", "账号或密码不能为空");
      } else {
        userLogin(this.loginFrom).then((res) => {
          this.userInfo = res;
          window.localStorage.setItem('sessionId', res.sessionId)
          this.$router.push('/')
        });
      }
    },
    changePassword(){
      console.log(this.$router.push('/forgetPassword'));
    },
    btnLogin(){
      document.onkeydown =e =>{
        if(e.keyCode === 13){
          this.login()
        }
      }
      
    }
  },
};
</script>
