/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-10 14:23:35
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-10 14:36:25
 */
import {Login} from '@/api/index'

export async function userLogin(data){
    let res = await Login.userLogin(data);
    return res
}